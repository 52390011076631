import {
  Box,
  Grid,
  Modal,
  Typography,
  Rating as Ratings,
  InputLabel,
  Button,
  Divider,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import bgImg from "../../Assets/images/bg.png";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Navbar from "../Components/Common/Navbar";
import CustomInput from "../Components/Custom/CustomInput";
import queryString from "query-string";
import { getSingleRestaurant, submitRating } from "../../api";
import Loader from "../Components/Common/Loader";
import Lottie from "react-lottie";
import anime from "../../Assets/anime/reviewd.json";
import anime2 from "../../Assets/anime/sorrry.json";
import { CgEditNoise } from "react-icons/cg";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { MdNoiseAware } from "react-icons/md";



const StyledRating = styled(Ratings)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <MdNoiseAware style={{fontSize:'12px'}}/>,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <MdNoiseAware style={{fontSize:'14px'}} />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <MdNoiseAware style={{fontSize:'16px'}}/>,
    label: 'Neutral',
  },
  4: {
    icon: <MdNoiseAware style={{fontSize:'18px'}} />,
    label: 'Satisfied',
  },
  5: {
    icon: <MdNoiseAware style={{fontSize:'20px'}} />,
    label: 'Very Satisfied',
  },
};


function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value]?.icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  backdrop: {
    transition: "0.1s all linear",
    backdropFilter: "blur(1px)",
    backgroundColor: "#000000b7",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 700, md: 700, sm: 500, xs: 400 },
  bgcolor: "#fff",
  // border: "2px solid #000",
  boxShadow: 24,
  boxSizing: "border-box",
  borderRadius: 1,
  height: { lg: "auto", md: "auto", sm: "auto", xs: "91vh" },

  overflow: "scroll",

};

const labels = {
  0.5: "Poor",
  1: "Poor+",
  1.5: "Fair",
  2: "Fair+",
  2.5: "Good",
  3: "Good",
  3.5: "Great",
  4: "Great",
  4.5: "Excellent",
  5: "Excellent",
};
const noise = {
  1: "Can't recall",
  2: "Low",
  3: "Moderate",
  4: "Noisy",
  5: "Too noisy",
};

const Rating = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { BookingID, RestaurantID, GuestID, RestaurantName } = queryParams;
  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: anime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: anime2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [open, setOpen] = useState(true);

  const [ratings, setRatings] = useState({
    foodRating: 0,
    overallRating: 0,
    valueForMoneyRating: 0,
    noiseLevelsRating: 0,
    serviceRating: 0,
    ambienceRating: 0,


  });

  const handleRatingChange = (event, newValue, ratingType) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [ratingType]: newValue,
    }));
  };
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isReviewed, setIsReviewed] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const handleSubmitRatings = async () => {
    setIsLoading(true);
    setIsReviewed(false);
    setIsSuccessful(false);
    await submitRating(
      ratings?.foodRating,
      ratings?.valueForMoneyRating,
      ratings?.noiseLevelsRating,
      ratings?.serviceRating,
      ratings?.ambienceRating,
      ratings?.overallRating,
      comment,
      RestaurantID,
      BookingID,
      GuestID
    )
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.error_message === "Booking already reviewed") {
          setIsReviewed(true);
        }
        if (res?.data?.status) {
          setIsSuccessful(true);
        }
        console.log(res);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  //   const [data, setData] = useState(null);

  //   const handleGetRestaurant = async () => {
  //   setIsLoading(true);
  //   await getSingleRestaurant(RestaurantID)
  //     .then((res) => {
  //       setIsLoading(false);
  //       console.log(res);
  //       setData(res?.data?.result[0]);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       // console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   handleGetRestaurant();
  // }, []);
  // console.log(isReviewed);

  const [areAllRatingsZero, setAreAllRatingsZero] = useState(true);

  useEffect(() => {
    const allZero = Object.values(ratings).every((value) => value === 0);
    setAreAllRatingsZero(allZero);
  }, [ratings]);


  return (
    <>
      <Box
        sx={{
          height: "100vh",
          overflow: "scroll",
          background: `url('${bgImg}')`,
          backgroundSize: "contain",
          display:'grid',
          placeItems:'center'
        }}
        className="hide_scrollbar"
      >
   <Box sx={{  height: { lg: "auto", md: "auto", sm: "auto", xs: "91vh" },  width: { lg: 700, md: 700, sm: 500, xs: 400 },
  bgcolor: "#fff",
  boxShadow: 24,
  boxSizing: "border-box",
  borderRadius: 3,}} className="hide_scrollbar">
          {isLoading && <Loader />}
          <Box sx={{ bgcolor: "#f2f2f2", p: 3, borderRadius:3 }}>
            <Typography
              sx={{ textAlign: "center", fontWeight: 500, fontSize: "16px" }}
            >
              Rate Your Experience At {RestaurantName}
            </Typography>
          </Box>
          {isReviewed ? (
            <>
              <Box align="center" sx={{ mt: 10 }}>
                <Lottie options={defaultOptions2} width={200} height={200} />

                <Typography sx={{ fontWeight: 700, mt: 3, fontSize: "20px" }}>
                  Sorry!!!
                </Typography>
                <Typography sx={{ fontWeight: 600, mt: 2, fontSize: "16px" }}>
                  Your booking has already been reviewed
                </Typography>
                <Typography
                  sx={{ width: "70%", mt: 1, fontSize: "12px", mb: 5 }}
                >
                  Thank you for your feedback!
                </Typography>

                <Typography
                  sx={{ fontWeight: 300, fontSize: "10px", color: "#BC172F" }}
                >
                  Powered By : <a href="reisty.com">Reisty</a>
                </Typography>
              </Box>
            </>
          ) : isSuccessful ? (
            <>
              <Box align="center" sx={{ mt: 10 }}>
                <Lottie options={defaultOptions} width={200} height={200} />

                <Typography sx={{ fontWeight: 700, mt: 3, fontSize: "18px" }}>
                  Thank you for dining with us!
                </Typography>
                <Typography
                  sx={{ width: "70%", mt: 1, fontSize: "12px", mb: 5 }}
                >
                  Your feedback helps us improve and provide the best experience
                  possible.
                </Typography>

                <Typography
                  sx={{ fontWeight: 300, fontSize: "10px", color: "#BC172F" }}
                >
                  Powered By : <a href="reisty.com">Reisty</a>
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ mt: 4, px: 3 }}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        boxSizing: "border-box",
                        border: "1px solid #d7d7d7",
                        borderRadius: "8px",
                        p: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          justifyContent:'space-between',
                          mb: 3,
                          boxSizing:'border-box'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "14px",
                              md: "14px",
                              sm: "12px",
                              xs: "12px",
                            },
                            width: "15%",
                      fontWeight:500,
                          }}
                        >
                          OverAll
                        </Typography>

                        <Ratings
                          size="small"
                          sx={{
                             width: {sm:"60%", xs:'45%'},
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#BC172F",
                            boxSizing: "border-box",
                              
                            ".MuiRating-label": {
                              display: "none",
                            },
                          }}
                          precision={0.5}
                          name="overallRating"
                          value={ratings.overallRating}
                          onChange={(event, newValue) =>
                            handleRatingChange(event, newValue, "overallRating")
                          }
                        />

                        <Typography sx={{ fontSize: {sm:"12px", xs:'10px'}, width: {sm:"10%", xs:'15%'}  }}>
                          {labels[ratings.overallRating]}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          columnGap: 2,
                          mb: 3,
                       
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "14px",
                              md: "14px",
                              sm: "12px",
                              xs: "12px",
                            },
                            width: "15%",
                            fontWeight:500,
                          }}
                        >
                    Food
                        </Typography>
                        <Ratings
                          size="small"
                          sx={{
                             width: {sm:"60%", xs:'45%'},
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#BC172F",
                            boxSizing: "border-box",
                            ".MuiRating-label": {
                              display: "none",
                            },
                          }}
                          precision={0.5}
                          name="foodRating"
                          value={ratings.foodRating}
                          onChange={(event, newValue) =>
                            handleRatingChange(
                              event,
                              newValue,
                              "foodRating"
                            )
                          }
                        />
                        <Typography sx={{ fontSize: {sm:"12px", xs:'10px'}, width: {sm:"10%", xs:'15%'}  }}>
                        {labels[ratings.foodRating]}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          columnGap: 2,
                          mb: 3,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "14px",
                              md: "14px",
                              sm: "12px",
                              xs: "12px",
                            },
                            width:'15%',
                            fontWeight:500
                          }}
                        >
                         Service
                        </Typography>
                        <Ratings
                         size="small"
                          sx={{
                             width: {sm:"60%", xs:'45%'},
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#BC172F",
                            boxSizing: "border-box",
                            ".MuiRating-label": {
                              display: "none",
                            },
                          }}
                          precision={0.5}
                          name="serviceRating"
                          value={ratings.serviceRating}
                          onChange={(event, newValue) =>
                            handleRatingChange(
                              event,
                              newValue,
                              "serviceRating"
                            )
                          }
                        />
                            <Typography sx={{ fontSize: {sm:"12px", xs:'10px'}, width: {sm:"10%", xs:'15%'}  }}>
                          {labels[ratings.serviceRating]}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          columnGap: 2,
                          mb: 3,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "14px",
                              md: "14px",
                              sm: "12px",
                              xs: "12px",
                            },
                            fontWeight:500,
                            width:'15%'
                          }}
                        >
                         Ambience
                        </Typography>
                        <Ratings
                       size="small"
                          sx={{
                             width: {sm:"60%", xs:'45%'},
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#BC172F",
                            boxSizing: "border-box",
                            ".MuiRating-label": {
                              display: "none",
                            },
                          }}
                          precision={0.5}
                          name="ambienceRating"
                          value={ratings.ambienceRating}
                          onChange={(event, newValue) =>
                            handleRatingChange(
                              event,
                              newValue,
                              "ambienceRating"
                            )
                          }
                        />
                            <Typography sx={{ fontSize: {sm:"12px", xs:'10px'}, width: {sm:"10%", xs:'15%'}  }}>
                          {labels[ratings.ambienceRating]}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          columnGap: 2,
                          mb: 3,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "14px",
                              md: "14px",
                              sm: "12px",
                              xs: "12px",
                            },
                            fontWeight:500,
                            width:'15%'
                          }}
                        >
                        Value
                        </Typography>
                        <Ratings
                          size="small"
                              sx={{
                            width: {sm:"60%", xs:'45%'},
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#BC172F",
                            boxSizing: "border-box",
                            ".MuiRating-label": {
                              display: "none",
                            },
                          }}
                          precision={0.5}
                          name="valueForMoneyRating"
                          value={ratings.valueForMoneyRating}
                          onChange={(event, newValue) =>
                            handleRatingChange(
                              event,
                              newValue,
                              "valueForMoneyRating"
                            )
                          }
                        />
                            <Typography sx={{ fontSize: {sm:"12px", xs:'10px'}, width: {sm:"10%", xs:'15%'}  }}>
                          {labels[ratings.valueForMoneyRating]}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          columnGap: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "14px",
                              md: "14px",
                              sm: "12px",
                              xs: "12px",
                            },
                            width:'15%',
                            fontWeight:500
                          }}
                        >
                         Noise Level
                        </Typography>
                        <StyledRating
                          size="small"
                        IconContainerComponent={IconContainer}

                              sx={{
                             width: {sm:"60%", xs:'45%'},
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems:'center',
                            color: "#BC172F",
                            boxSizing: "border-box",
                            ".MuiRating-label": {
                              display: "none",
                            },
                          }}
                       
                          name="noiseLevelsRating"
                          value={ratings.noiseLevelsRating}
                          onChange={(event, newValue) =>
                            handleRatingChange(event, newValue, "noiseLevelsRating")
                          }
                        />
                              <InputLabel sx={{ fontSize: {sm:"12px", xs:'10px'}, width: {sm:"10%", xs:'15%'}  }}>
                          {noise[ratings.noiseLevelsRating]}
                        </InputLabel>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Box sx={{ mt: 3 }}>
                  <InputLabel>Reviews</InputLabel>

                  <CustomInput
                    fullWidth
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    multiline
                    rows={4}
                    placeholder="Write a review"
                  />
                </Box>

                <Button
                  disabled={!comment && areAllRatingsZero}
                  onClick={handleSubmitRatings}
                  variant="contained"
                  sx={{ mt: 4, py: 2, borderRadius: "10px" }}
                  fullWidth
                >
                  Submit Review
                </Button>

                <Typography
                  sx={{ mt: 4, mb: 1, textAlign: "center", fontSize: "12px" }}
                >
                  @Reisty
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>


     

    </>
  );
};

export default Rating;
