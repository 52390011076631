import React, { useEffect, useState } from "react";
import Navbar from "../Components/Common/Navbar";
import Footer from "../Components/Footer/Footer";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Rating,
  TextField,
  Typography,
  Popover,
  MenuItem,
  ListItemButton,
  useMediaQuery,
  useTheme,
  LinearProgress,
  Modal,
  Divider,
} from "@mui/material";
import { TfiCommentAlt } from "react-icons/tfi";
import { SlLocationPin } from "react-icons/sl";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TbExternalLink } from "react-icons/tb";
import { MdOutlineFavoriteBorder } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import { GrLocationPin } from "react-icons/gr";
import { RiArrowDropDownFill } from "react-icons/ri";
import { FaMinus } from "react-icons/fa";
import { TiPlus } from "react-icons/ti";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IoIosCopy } from "react-icons/io";
import {
  addToFavourite,
  getOpeningHours,
  getSingleRestaurant,
} from "../../api";
import { MdOutlineDone } from "react-icons/md";
import Loader from "../Components/Common/Loader";
import OpenTag from "../Components/Common/OpenTag";
import { IoMdStar } from "react-icons/io";
import Carousel from "../Components/Common/Carousel";
import { useSnackbar } from "notistack";
import logo from "../../Assets/logo/logo-icon-red.svg";
import Calendar from "../Components/Common/Calendar";
import MapContainer from "../Components/Common/MapContainer";
import { useDispatch, useSelector } from "react-redux";
import { IoMdHeart } from "react-icons/io";
import { CloseOutlined } from "@mui/icons-material";
import CustomInput from "../Components/Custom/CustomInput";
import { MdOutlineTableRestaurant } from "react-icons/md";
import RatingBar from "../Components/Common/RatingBar";
import SEO from "../../utils/SEO";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  borderRadius: "10px",
  p: 3,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Restaurants = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [read, setRead] = useState(false)

  const [value, setValue] = React.useState(0);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const dateObject = new Date();
  const toDate = dateObject.toISOString().split("T")[0];

  const open = Boolean(anchorEl);
  const pid = open ? "simple-popover" : undefined;
  const open2 = Boolean(anchorEl2);
  const pid2 = open2 ? "simple-popover" : undefined;

  const [guest, setGuest] = useState(1);
  const [date, setDate] = useState(toDate);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const { id } = useParams();

  const handleGetRestaurant = async () => {
    setIsLoading(true);
    await getSingleRestaurant(id)
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        setData(res?.data?.result[0]);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  };

  useEffect(() => {
    handleGetRestaurant();
  }, [id]);

  const [openingHours, setOpeningHours] = useState(null);

  console.log(openingHours);

  const handleGetOpeningHours = async () => {
    setIsLoading(true);
    await getOpeningHours(data?.RestaurantId, date, guest)
      .then((res) => {
        setIsLoading(false);
if(res?.data?.status){

  setOpeningHours(res?.data?.result[0]?.GroupedTime);
}else{
  setOpeningHours(null)
     handleAlert("error", `${res?.data?.error_message}`)
}
      })
      .catch((err) => {
   handleAlert("error", `${err?.message}`)
     setOpeningHours(null)
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if(data){

      handleGetOpeningHours();
    }
  }, [id, date, guest, data]);

  const [selectedTime, setSelectedTime] = useState(null);

  const [favLoading, setFavLoading] = useState(false);
  const [favSuccess, setFavSuccess] = useState(false);
  const handleAddToFav = async () => {
    setFavLoading(true);
    setFavSuccess(false);
    await addToFavourite(id)
      .then((res) => {
        setFavLoading(false);
        setFavSuccess(true);
        // console.log(res);
      })
      .catch((err) => {
        setFavLoading(false);
        // console.log(err);
      });
  };

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((err) => console.error("Failed to copy:", err));
  };

  const [copied, setCopied] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  return (
    <>
    <SEO 
          title={`${id} | Explore and Book Reservation with Reisty in Lagos - Instantly`}
        description={`Explore the best dining experiences ${id} offers with Reisty! From trendy cafes to exquisite fine dining, find menus, read reviews, and book your table online today.`}
        name="Reisty"
        type="article"
                canonicalUrl={`https:/reisty.com/restaurant/${id}`}
    />
      {isLoading && <Loader />}
      <Navbar />

      <Box
        sx={{
          pt: { lg: 10, md: 10, sm: 10, xs: 9 },
          width: { xl: "75%", lg: "85%", sm: "90%", xs: "100%" },
          margin: "0 auto",
        }}
      >
        <Box
          sx={{
            bgcolor: "#ccc",
            height: { lg: "400px", md: "400px", sm: "300px", xs: "250px" },
          }}
        >
          {isLoading ? (
            <></>
          ) : (
            <>
              <Carousel
                images={data?.Photos}
                title={data?.RestaurantName}
                description={data?.Description}
              />
            </>
          )}
        </Box>
        <Box
          sx={{
            width: { lg: "100%", md: "100%", sm: "100%", xs: "90%" },
            margin: "0 auto",
          }}
        >
          <Grid container spacing={4} sx={{ mt: 3, mb: 16 }}>
            <Grid item lg={7.5} md={7.5} xs={12}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 3,
                    flexDirection: {
                      lg: "row",
                      md: "row",
                      sm: "column",
                      xs: "column",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: { lg: "60%", md: "60%", sm: "100%", xs: "100%" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 3,
                      }}
                    >
                      <InputLabel
                        sx={{
                          fontSize: {
                            lg: "26px",
                            md: "26px",
                            sm: "24px",
                            xs: "20px",
                          },
                          fontWeight: 700,
                          lineHeight: "25px",
                          color: "#151515",
                        }}
                      >
                        {data?.RestaurantName}
                      </InputLabel>
                      {data?.OpenFrom !== "No reservation hour" && (
                        <OpenTag hoursString={data?.OpenFrom} />
                      )}
                    </Box>

                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <SlLocationPin />
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "14px",
                            md: "14px",
                            sm: "12px",
                            xs: "10px",
                          },
                        }}
                      >
                        {data?.Address}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        mt: 1,
                        display: "flex",
                        alignItems: "center",
                        columnGap: 3,
                      }}
                    >
                      <Rating
                        readOnly
                        value={parseInt(data?.ReviewDetails?.Rating)}
                        precision={0.5}
                        size="small"
                        sx={{
                          color: "#BC172F",
                          fontSize: {
                            lg: "14px",
                            md: "14px",
                            sm: "12px",
                            xs: "12px",
                          },
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          columnGap: 2,
                          alignItems: "center",
                        }}
                      >
                        <TfiCommentAlt
                          style={{ fontSize: isMobile ? "12px" : "15px" }}
                        />
                        <Typography sx={{ fontSize: "12px" }}>
                          {data?.ReviewDetails?.Reviews?.length} reviews
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      sx={{
                        mt: 1,
                        fontWeight: 600,
                        fontSize: {
                          lg: "16px",
                          md: "16px",
                          sm: "14px",
                          xs: "12px",
                        },
                      }}
                    >
                      <span style={{ fontWeight: 300 }}>From</span> ₦
                      {data?.AverageCost?.toLocaleString()}
                    </Typography>
                  </Box>
                  {!isMobile && (
                    <Box>
                      <Button
                        onClick={() =>
                      isAuthenticated
                        ? 
                          handleAddToFav()
                        : navigate(`/login`, {
                            state: { redirectTo: location.pathname },
                          })
                    }
                  
                        startIcon={
                          favSuccess ? (
                            <IoMdHeart style={{ color: "#BC172F" }} />
                          ) : (
                            <MdOutlineFavoriteBorder />
                          )
                        }
                        sx={{
                          bgcolor: "#fae8ea",
                          fontWeight: 400,
                          border: "0.5px solid #BC172F",
                        }}
                      >
                        {favLoading ? (
                          <LinearProgress sx={{ width: "50px" }} />
                        ) : (
                          "Favourite"
                        )}
                      </Button>
                      <Button
                        onClick={handleOpen}
                        startIcon={<IoShareSocialOutline />}
                        sx={{
                          bgcolor: "#fae8ea",
                          fontWeight: 400,
                          border: "0.5px solid #BC172F",
                          ml: 3,
                        }}
                      >
                        Share
                      </Button>
                    </Box>
                  )}
                </Box>
                <Box sx={{ width: "100%", mt: 3 }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      variant="fullWidth"
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        label="About"
                        {...a11yProps(0)}
                        sx={{
                          fontSize: {
                            lg: "14px",
                            md: "14px",
                            sm: "12px",
                            xs: "12px",
                          },
                        }}
                      />
                      <Tab
                        label="Menu"
                        {...a11yProps(1)}
                        sx={{
                          fontSize: {
                            lg: "14px",
                            md: "14px",
                            sm: "12px",
                            xs: "12px",
                          },
                        }}
                      />
                      <Tab
                        label="Review"
                        {...a11yProps(2)}
                        sx={{
                          fontSize: {
                            lg: "14px",
                            md: "14px",
                            sm: "12px",
                            xs: "12px",
                          },
                        }}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <Typography
                    className={!read &&"four-line-ellipsis"}
                      sx={{
                        fontWeight: 300,
                        textAlign: "justify",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "12px",
                          xs: "12px",
                        },
                      transition:'0.2s all linear'
                      }}
                    >
                      {data?.Description}
                    </Typography>
                    <span onClick={()=>setRead(!read)} style={{cursor:'pointer', textDecoration:'underline'}}>{read  ?"Read Less " : "Read more"}</span>
                    <Box
                      sx={{
                        mt: 2,
                        bgcolor: "#fcf4ea",
                        p: 1,
                        px: 2,
                        border: "0.5px solid #d9d9d9",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#df8e13",
                          fontWeight: 500,
                          fontSize: {
                            lg: "14px",
                            md: "14px",
                            sm: "12px",
                            xs: "12px",
                          },
                        }}
                      >
                        Important Note
                      </Typography>
                      <Typography
                        sx={{
                          color: "#df8e13",
                          mt: 1,
                          fontWeight: 300,
                          fontSize: {
                            lg: "12px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                        }}
                      >
                        We offer a 15-minute grace period. If you anticipate
                        arriving more than 15 minutes past your reservation
                        time, kindly give us a call in advance.
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        mt: 4,
                        fontSize: {
                          lg: "14px",
                          md: "14px",
                          sm: "12px",
                          xs: "12px",
                        },
                      }}
                    >
                      Restaurant opens <b>from {data?.OpenFrom}</b>
                    </Box>
                    <Box sx={{ mt: 4 }}>
                      <Grid container spacing={2}>
                        <Grid container spacing={2}>
                          {!openingHours ||
                          openingHours.length === 0 ||
                          (openingHours[0].length === 0 &&
                            openingHours[1]?.length === 0) ? (
                            <>
                              <Box sx={{ p: 1, width: "100%", mt: 1 }}>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: 600,
                                    color: "#BC172F",
                                  }}
                                >
                                  No Available Time for the selected date
                                </Typography>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: 300,
                                    fontSize: "12px",
                                  }}
                                >
                                  You can try selecting another date
                                </Typography>
                              </Box>
                            </>
                          ) : (
                            <>
                              {isMobile ? (
                                <>
                                  <Box
                                  className="hide_scrollbar"
                                    sx={{
                                      display: "flex",
                                      ml: 3,
                                      overflow: "scroll",
                                      alignItems: "center",
                                      columnGap: 2,
                                      mt: 2,
                                    }}
                                  >
                                    {openingHours &&
                                      openingHours[0]?.map((time, index) => {
                                        return (
                                          <Box sx={{}}>
                                            <ListItemButton
                                              disabled={!time?.IsAvailable}
                                              onClick={() => {
                                                setSelectedTime(time?.Time);
                                                handleClose2();
                                              }}
                                              selected={
                                                selectedTime === time?.Time
                                              }
                                              sx={{
                                                borderRadius: "8px",
                                                bgcolor: "#bb182f",
                                                color: "#fff",
                                                transition: "0.1s all linear",
                                                display: "flex",
                                                "&:hover": {
                                                  bgcolor: "#bb182fb7",
                                                  border: "3px solid #d7d7d7",
                                                },
                                                "&.Mui-selected": {
                                                  bgcolor: "#bb182f",
                                                  border: "3px solid #000",
                                                },
                                              }}
                                            >
                                              <MdOutlineTableRestaurant />
                                              <Typography
                                                sx={{
                                                  fontSize: "10px",
                                                  whiteSpace: "nowrap",
                                                  textAlign: "center",
                                                  width: "100%",
                                                }}
                                              >
                                                {" "}
                                                {time?.Time}
                                              </Typography>
                                            </ListItemButton>
                                          </Box>
                                        );
                                      })}
                                    {openingHours &&
                                      openingHours[1]?.map((time, index) => {
                                        return (
                                          <Box sx={{}} key={index}>
                                            <ListItemButton
                                              disabled={!time?.IsAvailable}
                                              onClick={() => {
                                                setSelectedTime(time?.Time);
                                                handleClose2();
                                              }}
                                              selected={
                                                selectedTime === time?.Time
                                              }
                                              sx={{
                                                borderRadius: "8px",
                                                bgcolor: "#bb182f",
                                                color: "#fff",
                                                transition: "0.1s all linear",
                                                display: "flex",
                                                "&:hover": {
                                                  bgcolor: "#bb182fb7",
                                                  border: "3px solid #d7d7d7",
                                                },
                                                "&.Mui-selected": {
                                                  bgcolor: "#bb182f",
                                                  border: "3px solid #000",
                                                },
                                              }}
                                            >
                                              <MdOutlineTableRestaurant />
                                              <Typography
                                                sx={{
                                                  fontSize: "10px",
                                                  whiteSpace: "nowrap",
                                                  textAlign: "center",
                                                  width: "100%",
                                                }}
                                              >
                                                {" "}
                                                {time?.Time}
                                              </Typography>
                                            </ListItemButton>
                                          </Box>
                                        );
                                      })}
                                  </Box>
                                </>
                              ) : (
                                <>
                                  {openingHours &&
                                    openingHours[0]?.map((time, index) => {
                                      return (
                                        <Grid
                                          item
                                          lg={2}
                                          md={2}
                                          sm={3}
                                          xs={3}
                                          key={index}
                                          sx={{}}
                                        >
                                          <ListItemButton
                                            disabled={!time?.IsAvailable}
                                            onClick={() => {
                                              setSelectedTime(time?.Time);
                                              handleClose2();
                                            }}
                                            selected={
                                              selectedTime === time?.Time
                                            }
                                            sx={{
                                              borderRadius: "8px",
                                              bgcolor: "#bb182f",
                                              color: "#fff",
                                              transition: "0.1s all linear",
                                              display: "flex",
                                              "&:hover": {
                                                bgcolor: "#bb182fb7",
                                                border: "3px solid #d7d7d7",
                                              },
                                              "&.Mui-selected": {
                                                bgcolor: "#bb182f",
                                                border: "3px solid #000",
                                              },
                                            }}
                                          >
                                            <MdOutlineTableRestaurant />
                                            <Typography
                                              sx={{
                                                fontSize: "10px",
                                                whiteSpace: "nowrap",
                                                textAlign: "center",
                                                width: "100%",
                                              }}
                                            >
                                              {" "}
                                              {time?.Time}
                                            </Typography>
                                          </ListItemButton>
                                        </Grid>
                                      );
                                    })}
                                  {openingHours &&
                                    openingHours[1]?.map((time, index) => {
                                      return (
                                        <Grid
                                          item
                                          lg={2}
                                          md={2}
                                          sm={3}
                                          xs={3}
                                          key={index}
                                        >
                                          <ListItemButton
                                            disabled={!time?.IsAvailable}
                                            onClick={() => {
                                              setSelectedTime(time?.Time);
                                              handleClose2();
                                            }}
                                            selected={
                                              selectedTime === time?.Time
                                            }
                                            sx={{
                                              border: "1px solid #d7d7d7",
                                              borderRadius: "8px",
                                              bgcolor: "#bb182f",
                                              color: "#fff",
                                              transition: "0.2s all linear",
                                              "&:hover": {
                                                bgcolor: "#bb182fb7",
                                                border: "3px solid #d7d7d7",
                                              },
                                              "&.Mui-selected": {
                                                bgcolor: "#bb182f",
                                                border: "3px solid #000",
                                              },
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "12px",
                                                whiteSpace: "nowrap",
                                                textAlign: "center",
                                                width: "100%",
                                              }}
                                            >
                                              {" "}
                                              {time?.Time}
                                            </Typography>
                                          </ListItemButton>
                                        </Grid>
                                      );
                                    })}
                                </>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <>
                      <Typography
                        sx={{
                          fontWeight: 300,
                          fontSize: {
                            lg: "14px",
                            md: "14px",
                            sm: "12px",
                            xs: "12px",
                          },
                        }}
                      >
                        Explore the menu offerings at {data?.RestaurantName} and
                        discover the most popular dishes that people are
                        craving! From appetizers to desserts, take a look at
                        what's in store for you when you reserve a table at{" "}
                        {data?.RestaurantName} . You can also check out the
                        prices to get an idea of what to expect, in addition to
                        the flavors. Browse through the menu highlights from{" "}
                        {data?.RestaurantName} and let your taste buds indulge
                        in the delectable offerings. We're already getting
                        hungry just thinking about it...
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                          mt: 3,
                        }}
                      >
                        <TbExternalLink style={{ color: "#BC172F" }} />
                        <a href={data?.MenuLink} target="_blank">
                          <Typography color="primary">
                            Open restaurant's full menu
                          </Typography>
                        </a>
                      </Box>
                      {data?.Menu?.length > 0 && (
                        <>
                          <Box
                            sx={{
                              mt: 5,
                              border: "1px solid #d7d7d7",
                              borderRadius: "10px",
                              pb: 4,
                            }}
                          >
                            <Box
                              sx={{
                                bgcolor: "#BC172F",
                                p: 3,
                                fontWeight: 500,
                                fontSize: "18px",
                                color: "#fff",
                                borderRadius: "10px 10px 0px 0px",
                                fontSize: {
                                  lg: "14px",
                                  md: "14px",
                                  sm: "12px",
                                  xs: "12px",
                                },
                              }}
                            >
                              Menu Highlights
                            </Box>
                            <Box sx={{ mt: 2, px: 3 }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: 2,
                                }}
                              >
                                {/* <Avatar src={logo} variant="rounded" sx={{border:'1px solid #BC172F', objectFit:'cover'}} /> */}
                                <Typography
                                  color="primary"
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: {
                                      lg: "16px",
                                      md: "16px",
                                      sm: "14px",
                                      xs: "14px",
                                    },
                                  }}
                                >
                                  Main Courses
                                </Typography>
                              </Box>
                              {data?.Menu?.map((menu, index) => {
                                const isMainCourse =
                                  menu?.Category === "Main Courses";
                                return (
                                  <Box
                                    sx={{
                                      mt: 2,
                                      display: "flex",
                                      alignItems: "center",
                                      columnGap: 2,
                                      justifyContent: "space-between",
                                      ...(!isMainCourse && {
                                        display: "none",
                                      }),
                                    }}
                                  >
                                    <Box sx={{ width: "70%" }}>
                                      <InputLabel
                                        sx={{
                                          fontWeight: 500,
                                          fontSize: {
                                            lg: "14px",
                                            md: "14px",
                                            sm: "12px",
                                            xs: "12px",
                                          },
                                        }}
                                      >
                                        {menu?.Item}
                                      </InputLabel>
                                      <InputLabel
                                        sx={{
                                          fontSize: {
                                            lg: "12px",
                                            md: "12px",
                                            sm: "10px",
                                            xs: "10px",
                                          },
                                        }}
                                      >
                                        {menu?.Description}
                                      </InputLabel>
                                    </Box>
                                    <Typography
                                      sx={{
                                        fontWeight: 500,
                                        fontSize: {
                                          lg: "14px",
                                          md: "14px",
                                          sm: "12px",
                                          xs: "12px",
                                        },
                                      }}
                                    >
                                      ₦{menu?.Price?.toLocaleString()}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </Box>
                            <Box sx={{ mt: 3, px: 3 }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: 2,
                                }}
                              >
                                {/* <Avatar src={logo} variant="rounded" sx={{border:'1px solid #BC172F', objectFit:'cover'}} /> */}
                                <Typography
                                  color="primary"
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: {
                                      lg: "16px",
                                      md: "16px",
                                      sm: "14px",
                                      xs: "14px",
                                    },
                                  }}
                                >
                                  Starters/Appetizers
                                </Typography>
                              </Box>
                              {data?.Menu?.map((menu, index) => {
                                const isMainCourse =
                                  menu?.Category === "Starters/Appetizers";
                                return (
                                  <Box
                                    sx={{
                                      mt: 2,
                                      display: "flex",
                                      alignItems: "center",
                                      columnGap: 2,
                                      justifyContent: "space-between",
                                      ...(!isMainCourse && {
                                        display: "none",
                                      }),
                                    }}
                                  >
                                    <Box sx={{ width: "70%" }}>
                                      <InputLabel
                                        sx={{
                                          fontWeight: 500,
                                          fontSize: {
                                            lg: "14px",
                                            md: "14px",
                                            sm: "12px",
                                            xs: "12px",
                                          },
                                        }}
                                      >
                                        {menu?.Item}
                                      </InputLabel>
                                      <InputLabel
                                        sx={{
                                          fontSize: {
                                            lg: "12px",
                                            md: "12px",
                                            sm: "10px",
                                            xs: "10px",
                                          },
                                        }}
                                      >
                                        {menu?.Description}
                                      </InputLabel>
                                    </Box>
                                    <Typography
                                      sx={{
                                        fontWeight: 500,
                                        fontSize: {
                                          lg: "14px",
                                          md: "14px",
                                          sm: "12px",
                                          xs: "12px",
                                        },
                                      }}
                                    >
                                      ₦{menu?.Price?.toLocaleString()}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Box>
                        </>
                      )}
                    </>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <Typography
                      sx={{ fontWeight: 700, fontSize: "12px", mb: 3 }}
                    >
                      Reviews
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box>
                          <Typography sx={{ fontWeight: 500 }}>
                            Overall rating
                          </Typography>
                          <Typography
                            sx={{ fontWeight: 700, fontSize: "24px", my: 2 }}
                          >
                            {data?.ReviewDetails?.Rating || "--"}
                          </Typography>
                          <Rating
                            readOnly
                            value={4}
                            sx={{ color: "#BC172F", fontSize: "14px" }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box>
                          <RatingBar rate={5} value={67} />
                          <RatingBar rate={4} value={57} />
                          <RatingBar rate={3} value={47} />
                          <RatingBar rate={2} value={27} />
                          <RatingBar rate={1} value={17} />
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider sx={{ my: 1 }} />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        columnGap: 2,
                        overflow: "scroll",
                      }}
                    >
                      <Box>
                        <Typography sx={{ fontSize: "12px" }}>Food </Typography>
                        <Typography sx={{ fontWeight: 600, fontSize: "12px" }}>
                          {data?.ReviewDetails?.CategoryRating?.Food || "--"}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "12px" }}>
                          Service{" "}
                        </Typography>
                        <Typography sx={{ fontWeight: 600, fontSize: "12px" }}>
                          {data?.ReviewDetails?.CategoryRating?.Service || "--"}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "12px" }}>
                          Ambience{" "}
                        </Typography>
                        <Typography sx={{ fontWeight: 600, fontSize: "12px" }}>
                          {data?.ReviewDetails?.CategoryRating?.Ambience ||
                            "--"}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "12px" }}>
                          Noise{" "}
                        </Typography>
                        <Typography sx={{ fontWeight: 600, fontSize: "12px" }}>
                          {data?.ReviewDetails?.CategoryRating?.NoiseLevels ||
                            "--"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      {!data?.ReviewDetails?.Reviews ||
                      data?.ReviewDetails?.Reviews?.legth === 0 ? (
                        <></>
                      ) : (
                        <>
                          {data?.ReviewDetails?.Reviews.map((review, i) => (
                            <Box key={i} sx={{ pb: 2 }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: 2,
                                }}
                              >
                                <Avatar
                                  sx={{ bgcolor: "#013220", fontSize: "12px" }}
                                >
                                  {review?.ProfileImage ? (
                                    <img
                                      src={review?.ProfileImage}
                                      width={40}
                                    />
                                  ) : (
                                    review?.UserName?.substring(0, 2)
                                  )}
                                </Avatar>
                                <Box>
                                  <Typography sx={{ fontSize: "12px" }}>
                                    {review?.UserName || "Annymous"}
                                  </Typography>
                                  <Typography sx={{ fontSize: "10px" }}>
                                    {review?.MonthAndYear || "--"} .{" "}
                                    {review?.Time}
                                  </Typography>
                                </Box>
                              </Box>
                              <Rating
                                readOnly
                                value={review?.Rating}
                                precision={0.5}
                                sx={{
                                  fontSize: "12px",
                                  color: "#BC172F",
                                  my: 1,
                                }}
                              />
                              <Typography sx={{ fontSize: "10px" }}>
                                {review?.Comment}
                              </Typography>
                            </Box>
                          ))}
                        </>
                      )}
                    </Box>
                  </CustomTabPanel>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={4.5} md={4.5} sm={12} xs={12}>
              <Box>
                <Box
                  sx={{
                    border: "1px solid #d7d7d7",
                    borderRadius: "10px",
                    p: 3,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: {
                        lg: "18px",
                        md: "18px",
                        sm: "16px",
                        xs: "14px",
                      },
                    }}
                  >
                    Dine at {data?.RestaurantName}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                      mt: 1,
                    }}
                  >
                    <GrLocationPin style={{ color: "#BC172F" }} />
                    <Typography
                      sx={{
                        fontSize: {
                          lg: "14px",
                          md: "14px",
                          sm: "12px",
                          xs: "12px",
                        },
                      }}
                    >
                      {data?.LGA}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      mt: 3,
                      border: "1px solid #d7d7d7",
                      borderRadius: "10px",
                      p: 2,
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        sx={{ display: "grid", placeItems: "center" }}
                      >
                        <MenuItem sx={{ p: 0, width: "100%" }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",

                              pl: 2,
                              pb: 0.5,
                              boxSizing: "border-box",
                            }}
                            aria-describedby={pid2}
                            variant="contained"
                            onClick={handleClick2}
                          >
                            <Box>
                              <Typography
                                sx={{ fontSize: "12px", fontWeight: 300 }}
                              >
                                Time
                              </Typography>
                              <Typography
                                sx={{ fontWeight: 500, fontSize: "12px" }}
                              >
                                {selectedTime}
                              </Typography>
                            </Box>
                            <RiArrowDropDownFill />
                          </Box>
                        </MenuItem>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        sx={{ display: "grid", placeItems: "center" }}
                      >
                        <MenuItem sx={{ p: 0, width: "100%" }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              borderLeft: "1px solid #d7d7d7",
                              pl: 2,
                              pb: 0.5,
                              boxSizing: "border-box",
                            }}
                            aria-describedby={pid}
                            variant="contained"
                            onClick={handleClick}
                          >
                            <Box>
                              <Typography
                                sx={{ fontSize: "12px", fontWeight: 300 }}
                              >
                                Date
                              </Typography>
                              <Typography
                                sx={{ fontWeight: 500, fontSize: "12px" }}
                              >
                                {formatDate(date)}
                              </Typography>
                            </Box>
                            <RiArrowDropDownFill />
                          </Box>
                        </MenuItem>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ display: "grid", placeItems: "center" }}
                      >
                        <Box
                          sx={{ borderTop: "1px solid #d7d7d7", width: "100%" }}
                        >
                          <TextField
                            fullWidth
                            margin="dense"
                            value={guest}
                            InputProps={{
                              style: {
                                fontFamily: "outfit",
                                fontSize: "13px",
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => {
                                      guest > 1 && setGuest((prev) => prev - 1);
                                    }}
                                    sx={{
                                      borderRadius: "8px",
                                      border: "0.5px solid #d7d7d7",
                                    }}
                                  >
                                    <FaMinus style={{ fontSize: "12px" }} />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => setGuest((prev) => prev + 1)}
                                    sx={{
                                      borderRadius: "8px",
                                      border: "0.5px solid #d7d7d7",
                                      ml: 2,
                                    }}
                                  >
                                    <TiPlus style={{ fontSize: "12px" }} />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Button
                    onClick={() =>
                      isAuthenticated
                        ? navigate(
                            `/restaurant/${data?.RestaurantName}/${id}/booking?ReservationDay=${date}&ReservationTime=${selectedTime}&PartySize=${guest}&Deposit=${data?.DepositPerPerson}`
                          )
                        : navigate(`/login`, {
                            state: { redirectTo: location.pathname },
                          })
                    }
                    disabled={!selectedTime || !date || !guest}
                    variant="contained"
                    sx={{ mt: 3, borderRadius: "30px", py: 2 }}
                    fullWidth
                  >
                    Book
                  </Button>
                </Box>

                <Box sx={{ mt: 3, height: "300px", overflow: "hidden" }}>
                  <MapContainer
                    variant="map"
                    restaurantAddress={data?.Address}
                  />
                </Box>
                <Box
                  sx={{
                    p: 3,
                    boxSizing: "border-box",
                    border: "1px solid #d7d7d7",
                  }}
                >
                  <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
                    Locate {data?.RestaurantName}
                  </Typography>

                  <Typography sx={{ mt: 0.5, fontSize: "12px" }}>
                    {data?.Address}
                  </Typography>

                  <Box sx={{ mt: 3 }}>
                    <a href={`tel:${data?.PhoneNumaber}`}>
                      <Typography color={"primary"}>
                        {data?.PhoneNumaber}
                      </Typography>
                    </a>
                    <Box sx={{ mt: 2 }}>
                      <MapContainer
                        variant="button"
                        restaurantAddress={data?.Address}
                      />
                    </Box>
                    {
                      isValidURL(data?.Restaurantwebsite) && (
      <Box sx={{ mt: 2 }}>
                      <a href={`${data?.Restaurantwebsite}`} target="_blank">
                        <Typography color="primary">
                          Restaurant Website
                        </Typography>
                      </a>
                    </Box>
                      )
                    }
              
                    <Box sx={{ mt: 2 }}>
                      <a href={`mailto:${data?.EmailAddress}`}>
                        <Typography color="primary">
                          {data?.EmailAddress}
                        </Typography>
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />

      <Popover
        id={pid}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box>
          <Calendar
            future={false}
            past={true}
            onChildValue={(value) => {
              setDate(value);
              setAnchorEl(null);
              console.log(date);
            }}
          />
        </Box>
      </Popover>

      <Popover
        id={pid2}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            width: { lg: "400px", md: "400px", sm: "350px", xs: "300px" },
            boxSizing: "border-box",
            p: 2,
          }}
        >
          <Grid container spacing={2}>
            {!openingHours ||
            openingHours.length === 0 ||
            (openingHours[0].length === 0 && openingHours[1]?.length === 0) ? (
              <>
                <Box sx={{ p: 1, width: "100%", mt: 1 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: 600,
                      color: "#BC172F",
                    }}
                  >
                    No Available Time for the selected date
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: 300,
                      fontSize: "12px",
                    }}
                  >
                    You can try selecting another date
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                {openingHours &&
                  openingHours[0]?.map((time, index) => {
                    return (
                      <Grid item lg={3} md={3} sm={4} xs={4} key={index}>
                        <ListItemButton
                            disabled={!time?.IsAvailable}
                          onClick={() => {
                            setSelectedTime(time?.Time);
                            handleClose2();
                          }}
                          selected={selectedTime === time?.Time}
                          sx={{
                            border: "1px solid #d7d7d7",
                            borderRadius: "8px",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "12px", whiteSpace: "nowrap" }}
                          >
                            {" "}
                            {time?.Time}
                          </Typography>
                        </ListItemButton>
                      </Grid>
                    );
                  })}
                {openingHours &&
                  openingHours[1]?.map((time, index) => {
                    return (
                      <Grid item lg={3} md={3} sm={4} xs={4} key={index}>
                        <ListItemButton
                            disabled={!time?.IsAvailable}
                          onClick={() => {
                            setSelectedTime(time?.Time);
                            handleClose2();
                          }}
                          selected={selectedTime === time?.Time}
                          sx={{
                            border: "1px solid #d7d7d7",
                            borderRadius: "8px",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "12px", whiteSpace: "nowrap" }}
                          >
                            {" "}
                            {time?.Time}
                          </Typography>
                        </ListItemButton>
                      </Grid>
                    );
                  })}
              </>
            )}
          </Grid>
        </Box>
      </Popover>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
                Share link
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                Recommend<b> {data?.RestaurantName}</b> to a friend.
              </Typography>
            </Box>
            <IconButton onClick={handleCloseModal}>
              <CloseOutlined />
            </IconButton>
          </Box>

          <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
            <CustomInput
              size="small"
              fullWidth
              value={`https://www.reisty.com/restaurant/${id}`}
            />
            <IconButton
              onClick={() =>
                handleCopy(`https://www.reisty.com/restaurant/${id}`)
              }
            >
              {copied ? <MdOutlineDone /> : <IoIosCopy />}
            </IconButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Restaurants;

function formatDate(inputDate) {
  // Create a Date object
  const date = new Date(inputDate);

  // Define an array of day and month names
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract the day of the week, month, and day of the month
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = monthsOfYear[date.getMonth()];
  const dayOfMonth = date.getDate();

  // Format the date string
  const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;

  return formattedDate;
}

const isValidURL = (string) => {
  try {
    // Try to create a new URL object
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};
